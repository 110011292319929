import React from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { MonitorInput, Customer } from '../../../../types';

interface OptionalInputsProps {
    showOptionalInputs: boolean;
    setShowOptionalInputs: (show: boolean) => void;
    newItem: MonitorInput;
    setNewItem: (item: MonitorInput) => void;
    handleSizeRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput | null) => void;
    customerData: Customer | null
}

const OptionalInputs: React.FC<OptionalInputsProps> = ({
    showOptionalInputs,
    setShowOptionalInputs,
    newItem,
    setNewItem,
    handleSizeRangeChange,
    customerData
}) => (
    <div className={`relative border rounded-lg transition-all duration-300 ease-in-out ${showOptionalInputs ? 'pb-4' : ''}`}>
        <button
            onClick={() => setShowOptionalInputs(!showOptionalInputs)}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 sm:py-3 px-4 rounded-lg transition-colors duration-200 ease-in-out flex items-center justify-center"
        >
            {showOptionalInputs ? <ChevronUp size={20} className="mr-2" /> : <ChevronDown size={20} className="mr-2" />}
            {showOptionalInputs ? 'Hide' : 'Show'} Optional Inputs
        </button>
        {showOptionalInputs && (
            <div className="space-y-4 p-4 rounded">
                <div className="flex flex-col space-y-2">
                    <label className="text-sm font-medium">Size Range: {newItem.sizeRange}</label>
                    <div className="flex items-center space-x-2">
                        <span className="text-xs">36</span>
                        <input
                            type="range"
                            min="36"
                            max="50"
                            value={newItem.sizeRange.split('-')[0]}
                            onChange={(e) => handleSizeRangeChange(e, 'from', null)}
                            className="flex-grow"
                        />
                        <input
                            type="range"
                            min="36"
                            max="50"
                            value={newItem.sizeRange.split('-')[1]}
                            onChange={(e) => handleSizeRangeChange(e, 'to', null)}
                            className="flex-grow"
                        />
                        <span className="text-xs">50</span>
                    </div>
                </div>
                {customerData && customerData.purchases.monitor.includes("private inputs") && (
                    <div className="flex justify-center items-center space-x-2">
                        <input
                            type="checkbox"
                            id="privateCheckbox"
                            checked={newItem.private || false}
                            onChange={(e) => setNewItem({ ...newItem, private: e.target.checked })}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <label htmlFor="privateCheckbox" className="text-sm font-medium">Private</label>
                    </div>
                )}

                <input
                    type="text"
                    placeholder="Special webhook"
                    value={newItem.specialWebhook}
                    onChange={(e) => setNewItem({ ...newItem, specialWebhook: e.target.value })}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                />
                <input
                    type="text"
                    placeholder="Note"
                    value={newItem.note}
                    onChange={(e) => setNewItem({ ...newItem, note: e.target.value })}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                />
                <input
                    type="number"
                    placeholder="Price limit"
                    value={newItem.priceLimit || ''}
                    onChange={(e) => setNewItem({ ...newItem, priceLimit: e.target.value ? Number(e.target.value) : undefined })}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                />

            </div>
        )}
    </div>
);

export default OptionalInputs;