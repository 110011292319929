import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom toast theme
const toastTheme = {
    dark: {
        primary: '#1a1c2a',     // Darker background
        secondary: '#2a2d3e',   // Slightly lighter for contrast
        success: '#4caf50',
        error: '#f44336',
        warning: '#ff9800',
        info: '#2196f3',
        progressBar: '#3f4356', // Lighter progress bar
    },
};

// Custom ToastContainer component with optional limit parameter
const CustomToastContainer = ({ limit }: { limit?: number }) => (
    <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={limit} // Optional limit parameter
        toastStyle={{
            background: toastTheme.dark.primary,
            color: '#ffffff',
        }}
        progressStyle={{
            background: toastTheme.dark.progressBar,
        }}
    />
);

// Custom toast functions
const customToast = {
    success: (message: string) => toast.success(message, {
        style: { background: toastTheme.dark.primary, color: '#ffffff' },
        progressStyle: { background: toastTheme.dark.success },
    }),
    error: (message: string) => toast.error(message, {
        style: { background: toastTheme.dark.primary, color: '#ffffff' },
        progressStyle: { background: toastTheme.dark.error },
    }),
    warning: (message: string) => toast.warning(message, {
        style: { background: toastTheme.dark.primary, color: '#ffffff' },
        progressStyle: { background: toastTheme.dark.warning },
    }),
    info: (message: string) => toast.info(message, {
        style: { background: toastTheme.dark.primary, color: '#ffffff' },
        progressStyle: { background: toastTheme.dark.info },
    }),
    loading: (message: string) => toast.loading(message, {
        style: { background: toastTheme.dark.primary, color: '#ffffff' },
    }),
};

export { CustomToastContainer, customToast };
