import React from 'react';
import { Barcode, Globe, Link } from 'lucide-react';

interface ExampleInputsProps {
    inputs: string[];
}

const determineInputType = (input: string): 'url' | 'ean' | 'sku' | 'unknown' => {
    if (input.startsWith('http://') || input.startsWith('https://')) {
        return 'url';
    } else if (/^\d{13}$/.test(input)) {
        return 'ean';
    } else if (/^[A-Z0-9-]{6,12}$/.test(input)) {
        return 'sku';
    } else {
        return 'unknown';
    }
};

const InputExample: React.FC<{ value: string }> = ({ value }) => {
    const type = determineInputType(value);
    let Icon, iconColor;
    switch (type) {
        case 'sku':
            Icon = Barcode;
            iconColor = 'text-blue-400';
            break;
        case 'ean':
            Icon = Barcode;
            iconColor = 'text-green-400';
            break;
        case 'url':
            Icon = Globe;
            iconColor = 'text-purple-400';
            break;
        default:
            Icon = Link;
            iconColor = 'text-gray-400';
    }

    return (
        <div className="flex items-center bg-[#212332] p-2 rounded">
            <Icon className={`w-5 h-5 mr-3 ${iconColor}`} />
            <span className="text-sm truncate">{value}</span>
        </div>
    );
};

const ExampleInputs: React.FC<ExampleInputsProps> = ({ inputs }) => {
    return (
        <div className="border p-4 rounded-lg">
            <h3 className="text-s font-semibold text-gray-400 mb-3">Example Inputs:</h3>
            <div className="space-y-2">
                {inputs.map((input, index) => (
                    <InputExample key={index} value={input} />
                ))}
            </div>
        </div>
    );
};

export default ExampleInputs;