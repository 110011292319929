// const mode = "prod";
const mode = "prod";

let API_URL;
let DISCORDOAUTH;
let DASHBOARDURL;

if (mode === "dev") {
    API_URL = "http://127.0.0.1:5003";
    DASHBOARDURL = "http://localhost:3000/";
    DISCORDOAUTH = "https://discord.com/api/oauth2/authorize?client_id=1135503633954373653&redirect_uri=http%3A%2F%2F127.0.0.1%3A3000%2Fcallback&response_type=code&scope=identify%20guilds&state=1135503633954373653";
} else if (mode === "prod") {
    API_URL = 'https://api.solvextools.com';
    DASHBOARDURL = "http://dashboard.solvextools.com/";
    DISCORDOAUTH = "https://discord.com/api/oauth2/authorize?client_id=1153743199853097050&redirect_uri=https%3A%2F%2Fdashboard.solvextools.com%2Fcallback&response_type=code&scope=identify%20guilds&state=1153743199853097050";
}

export { API_URL, DISCORDOAUTH, DASHBOARDURL };
