import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { Check, X } from 'lucide-react';
import axios from 'axios';
import "../../styles/command_popup_styles.css";
import { API_URL } from '../../constants';






interface PermissionData {
    channels?: {
        id: string;
        permission: boolean;
    }[];
    members?: {
        id: string;
        permission: boolean;
    }[];
    roles?: {
        id: string;
        permission: boolean;
    }[];
}

interface Command {
    id: string;
    name: string;
    permissions: PermissionData;
}

interface Roles {
    id: string;
    name: string;
    color: number;
}

interface Members {
    id: string;
    name: string;
    avatar: string;
}

interface Channels {
    id: string;
    name: string;
}

interface ExtendedRoles extends Roles {
    permission: boolean;
}

interface ExtendedMembers extends Members {
    permission: boolean;
}

interface ExtendedChannels extends Channels {
    permission: boolean;
}

interface PopupProps {
    current_command: Command;
    handle_close: () => void;
    handle_show_success: () => void;
    roles: Roles[];
    members: Members[];
    channels: Channels[];
    server_id: string;
    app_id: string;
}

const customStyles = {
    input: (provided: any) => ({
        ...provided,
        color: "white",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '270px',
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white',
        '&:active': {
            backgroundColor: '#f2f2f2',
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd',
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),
};

const OptionWithImage = (props: any) => (
    <components.Option {...props}>
        <img src={props.data.avatar} alt={props.data.name} style={{ width: '20px', marginRight: '8px', borderRadius: "50%" }} />
        {props.data.name}
    </components.Option>
);

const SingleValueWithImage = (props: any) => (
    <components.SingleValue {...props}>
        <img src={props.data.avatar} alt={props.data.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: "50%" }} />
        {props.data.name}
    </components.SingleValue>
);

const combineWithPermissionRoles = (selectedItems: Roles[], permissionItems: any[]): ExtendedRoles[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const combineWithPermissionChannels = (selectedItems: Channels[], permissionItems: any[]): ExtendedChannels[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const combineWithPermissionMembers = (selectedItems: Members[], permissionItems: any[]): ExtendedMembers[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const CommandPopUp: React.FC<PopupProps> = ({ current_command, handle_close, roles, members, channels, server_id, app_id, handle_show_success }) => {
    const [selectedRoles, setSelectedRoles] = useState<Roles[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<Channels[]>([]);
    const [showEdit, setShowEdit] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState("");
    const [showLastStep, setShowLastStep] = useState(false);
    const [mode, setMode] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const permission = current_command.permissions;

    const [selectedRolesNew, setSelectedRolesNew] = useState<ExtendedRoles[]>(
        combineWithPermissionRoles(selectedRoles, permission.roles || [])
    );
    const [selectedMembersNew, setSelectedMembersNew] = useState<ExtendedMembers[]>(
        combineWithPermissionMembers(selectedMembers, permission.members || [])
    );
    const [selectedChannelsNew, setSelectedChannelsNew] = useState<ExtendedChannels[]>(
        combineWithPermissionChannels(selectedChannels, permission.channels || [])
    );

    useEffect(() => {
        setSelectedRolesNew(combineWithPermissionRoles(selectedRoles, permission.roles || []));
    }, [selectedRoles, permission.roles]);

    useEffect(() => {
        setSelectedMembersNew(combineWithPermissionMembers(selectedMembers, permission.members || []));
    }, [selectedMembers, permission.members]);

    useEffect(() => {
        setSelectedChannelsNew(combineWithPermissionChannels(selectedChannels, permission.channels || []));
    }, [selectedChannels, permission.channels]);

    useEffect(() => {
        const sum = selectedChannels.length + selectedMembers.length + selectedRoles.length;
        setBtnDisabled(sum === 0);
    }, [selectedChannels, selectedMembers, selectedRoles]);

    const handleModeChange = (selectedOption) => {
        setMode(selectedOption.value);
    };

    const handleRolePermissionChange = (roleId: string) => {
        setSelectedRolesNew(prevState => prevState.map(role =>
            role.id === roleId ? { ...role, permission: !role.permission } : role
        ));
    };

    const handleMemberPermissionChange = (memberId: string) => {
        setSelectedMembersNew(prevState => prevState.map(member =>
            member.id === memberId ? { ...member, permission: !member.permission } : member
        ));
    };

    const handleChannelPermissionChange = (channelId: string) => {
        setSelectedChannelsNew(prevState => prevState.map(channel =>
            channel.id === channelId ? { ...channel, permission: !channel.permission } : channel
        ));
    };

    const handleSelectedMembers = (selected) => {
        setSelectedMembers(selected);
    };

    const handleSelectedChannels = (selected) => {
        setSelectedChannels(selected);
    };

    const handleSelectedRoles = (selected) => {
        setSelectedRoles(selected);
    };

    const handleSave = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/update/bot/commands`, {
                selected_channels: selectedChannelsNew,
                selected_roles: selectedRolesNew,
                selected_members: selectedMembersNew,
                app_id: app_id,
                guild_id: server_id,
                command_id: current_command.id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                handle_show_success();
                handle_close();
            } else if (response.status === 203) {
                setErrorUpdate("ERROR: Not enough permission!");
            } else {
                setErrorUpdate("An error occurred!");
            }
        } catch {
            setErrorUpdate("An error occurred!");
        }
    };

    const roleWithLabel = roles.map((role) => ({
        ...role,
        value: role.id,
        label: role.name,
    }));

    const membersWithLabel = members.map((member) => ({
        ...member,
        label: member.name,
        value: member.id,
    }));

    const channelsWithLabel = channels.map((channel) => ({
        ...channel,
        label: channel.name,
        value: channel.id,
    }));

    const modeOptions = [
        { "label": "Channel", "value": "channel" },
        { "label": "Role", "value": "role" },
        { "label": "Member", "value": "member" }
    ];

    const handleBackMenu = () => {
        clearSelections();
        setShowEdit(false);
    };

    const handleBackEdit = () => {
        setShowEdit(true);
        setShowLastStep(false);
    };

    const handleLastStep = () => {
        setShowEdit(false);
        setShowLastStep(true);
    };

    const changeEdit = () => {
        setShowEdit(!showEdit);
        loopThroughData();
    };

    const getPermissionSymbol = (permission) => {
        return permission ? <Check className="white-icon" /> : <X className="white-icon" />;
    };

    const loopThroughData = () => {
        if (permission.channels && permission.members && permission.roles) {
            permission.members.forEach((member) => {
                const matchedMember = members.find((item) => item.id === member.id);
                if (matchedMember) {
                    const memberNew = {
                        ...matchedMember,
                        label: matchedMember.name,
                        value: matchedMember.id,
                    };
                    setSelectedMembers((prevMembers) => [...prevMembers, memberNew]);
                }
            });

            permission.roles.forEach((role) => {
                const matchedRole = roles.find((item) => item.id === role.id);
                if (matchedRole) {
                    const roleNew = {
                        ...matchedRole,
                        label: matchedRole.name,
                        value: matchedRole.id,
                        color: matchedRole.color,
                    };
                    setSelectedRoles((prevRoles) => [...prevRoles, roleNew]);
                }
            });

            permission.channels.forEach((channel) => {
                const matchedChannel = channels.find((item) => item.id.toString() === channel.id.toString());
                if (matchedChannel) {
                    const channelNew = {
                        ...matchedChannel,
                        label: matchedChannel.name,
                        value: matchedChannel.id,
                    };
                    setSelectedChannels((prevChannels) => [...prevChannels, channelNew]);
                }
            });
        }
    };

    const mapIdsToNames = (data: PermissionData) => {
        const idToNameMap = new Map<string, string>();

        roles.forEach((role) => {
            idToNameMap.set(role.id, role.name);
        });

        members.forEach((member) => {
            idToNameMap.set(member.id, member.name);
        });

        channels.forEach((channel) => {
            idToNameMap.set(channel.id, channel.name);
        });

        if (permission.channels && permission.members && permission.roles) {
            return {
                ...data,
                channels: permission.channels.map((channel) => ({
                    id: idToNameMap.get(channel.id) || channel.id,
                    permission: channel.permission,
                })),
                members: permission.members.map((member) => ({
                    id: idToNameMap.get(member.id) || member.id,
                    permission: member.permission,
                })),
                roles: permission.roles.map((role) => ({
                    id: idToNameMap.get(role.id) || role.id,
                    permission: role.permission,
                })),
            };
        }
        return data;
    };

    const clearSelections = () => {
        setSelectedChannels([]);
        setSelectedRoles([]);
        setSelectedMembers([]);
    };

    const updatedData = mapIdsToNames(permission);

    return (
        <div className='edit-popup'>
            <p>{current_command.name}</p>
            {showEdit && (
                <div>
                    <div className='close-perms-popup' onClick={handle_close}>x</div>
                    <div className='popup-row'>
                        <div className="popup-content-columns">
                            <div className="section">
                                <div>
                                    <label>Mode</label>
                                    <Select
                                        classNamePrefix="mode"
                                        options={modeOptions}
                                        styles={customStyles}
                                        value={mode}
                                        onChange={handleModeChange}
                                        isSearchable={false}
                                        placeholder="Select Mode"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#509dfd',
                                            },
                                        })}
                                    />
                                </div>

                                {mode === "channel" && (
                                    <div>
                                        <label>Channels:</label>
                                        <Select
                                            classNamePrefix="channel"
                                            isSearchable={true}
                                            styles={customStyles}
                                            onChange={handleSelectedChannels}
                                            options={channelsWithLabel}
                                            value={selectedChannels}
                                            isMulti
                                            placeholder="Select Channel"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                                {mode === "role" && (
                                    <div>
                                        <label>Roles:</label>
                                        <Select
                                            classNamePrefix="role"
                                            isSearchable={true}
                                            options={roleWithLabel}
                                            styles={customStyles}
                                            value={selectedRoles}
                                            onChange={handleSelectedRoles}
                                            isMulti
                                            placeholder="Select Role"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                                {mode === "member" && (
                                    <div>
                                        <label>Members:</label>
                                        <Select
                                            classNamePrefix="member"
                                            isSearchable={true}
                                            styles={customStyles}
                                            options={membersWithLabel}
                                            components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                                            onChange={handleSelectedMembers}
                                            value={selectedMembers}
                                            isMulti
                                            placeholder="Select Member"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='popup-contentcolumns'>
                            <div className="selection-container">
                                <p className='selection-title'>Selections</p>
                                {selectedChannels.length > 0 && (
                                    <div>
                                        <div className="selection-group">
                                            <p className='group-title'>Channels</p>
                                            {selectedChannels.map((channel) => (
                                                <p key={channel.id} className="item-name">{channel.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {selectedRoles.length > 0 && (
                                    <div>
                                        <div className="selection-group">
                                            <p className='group-title'>Roles</p>
                                            {selectedRoles.map((role) => (
                                                <p key={role.id} className="item-name">{role.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selectedMembers.length > 0 && (
                                    <div>
                                        <div className="selection-group">
                                            <p className='group-title'>Members</p>
                                            {selectedMembers.map((member) => (
                                                <p key={member.id} className="item-name">{member.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selectedMembers.length === 0 && selectedRoles.length === 0 && selectedChannels.length === 0 && (
                                    <div>
                                        <p>Please pick at least one permission!</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="base_btn" onClick={handleBackMenu}>Back</button>
                        <button className="base_btn" onClick={handleLastStep} disabled={btnDisabled}>Next Step</button>
                    </div>
                </div>
            )}
            {!showEdit && !showLastStep && (
                <div className='centered-perms'>
                    <div className='close-perms-popup' onClick={handle_close}>x</div>

                    <h2 className="permissions-header">Current Permissions:</h2>
                    <div className="permissions-container">
                        {updatedData?.channels && updatedData?.channels.length > 0 && (
                            <div className="permissions-section">
                                <h3 className="section-header">Channels</h3>
                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.channels.map((channel) => (
                                            <tr key={channel.id}>
                                                <td>{channel.id}</td>
                                                <td>{getPermissionSymbol(channel.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {updatedData?.members && updatedData?.members.length > 0 && (
                            <div className="permissions-section">
                                <h3 className="section-header">Members</h3>
                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.members.map((member) => (
                                            <tr key={member.id}>
                                                <td>{member.id}</td>
                                                <td>{getPermissionSymbol(member.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {updatedData?.roles && updatedData?.roles.length > 0 && (
                            <div className="permissions-section">
                                <h3 className="section-header">Roles</h3>
                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.roles.map((role) => (
                                            <tr key={role.id}>
                                                <td>{role.id}</td>
                                                <td>{getPermissionSymbol(role.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {!updatedData && (
                            <div>No customized permissions found!</div>
                        )}
                    </div>
                    <button className="base_btn" onClick={handle_close}>Cancel</button>
                    <button className='base_btn' onClick={changeEdit}>Edit permissions</button>
                </div>
            )}
            {!showEdit && showLastStep && (
                <div className="permissions-popup">
                    <div className='close-perms-popup' onClick={handle_close}>x</div>
                    <div className="permissions-content">
                        {selectedRolesNew.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Roles</div>
                                {selectedRolesNew.map(role => (
                                    <div key={role.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="role-checkbox"
                                            checked={role.permission}
                                            onChange={() => handleRolePermissionChange(role.id)}
                                        />
                                        <span>{role.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                        {selectedChannelsNew.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Channels</div>
                                {selectedChannelsNew.map(channel => (
                                    <div key={channel.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="channel-checkbox"
                                            checked={channel.permission}
                                            onChange={() => handleChannelPermissionChange(channel.id)}
                                        />
                                        <span>{channel.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                        {selectedMembersNew.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Members</div>
                                {selectedMembersNew.map(member => (
                                    <div key={member.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="member-checkbox"
                                            checked={member.permission}
                                            onChange={() => handleMemberPermissionChange(member.id)}
                                        />
                                        <span>{member.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='button-container'>
                        <button className="base_btn" onClick={handleBackEdit}>Back</button>
                        <button className="base_btn" onClick={handleSave}>Save</button>
                        <p className='error_update'>{errorUpdate}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommandPopUp;