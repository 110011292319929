import React, { useState, useEffect } from "react";
import axios from "axios";
import { UserPlus, Copy, X, Trash2 } from "lucide-react";
import ReactDOM from 'react-dom';
import { API_URL } from "../../constants";



interface AccessProps {
    handleLogOut: () => void;
    handleBlur: () => void;
    app_id: string;
}

interface User {
    discord_id: string;
    icon: string;
    name: string;
    permission: number;
}

const DashboardAccess: React.FC<AccessProps> = ({ handleLogOut, handleBlur, app_id }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [inviteLink, setInviteLink] = useState("");
    const [copyStatus, setCopyStatus] = useState("Copy");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        getDashboardUsers();
    }, []);

    const getDashboardUsers = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setUsers(response.data.data.bots[0].users);
            }
        } catch (error) {
            handleLogOut();
        }
    };

    const getExpirationDate = () => {
        const expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        return expirationDate.toLocaleString('en-EU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });
    };

    const handleInvitePopup = async () => {
        setShowPopup(!showPopup);
        handleBlur();
        if (!showPopup) {
            try {
                const response = await axios.post(`${API_URL}/v1/create/bot/invite`, {
                    app_id: app_id,
                    permission: 2,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });
                if (response.status === 200) {
                    setInviteLink(response.data.data.link);
                } else if (response.status === 203) {
                    setErrorMessage("ERROR: Not enough permission!");
                } else {
                    setErrorMessage("An error occurred!");
                }
            } catch {
                setErrorMessage("An error occurred!");
            }
        } else {
            setInviteLink("");
            setCopyStatus("Copy");
        }

    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(inviteLink);
            setCopyStatus("Copied!");
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const removeUser = async (user: User) => {
        try {
            const response = await axios.post(`${API_URL}/v1/remove/bot/user`, {
                app_id: app_id,
                user_id: user.discord_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                getDashboardUsers();
            }
        } catch {
            setErrorMessage("An error occurred!");
        }
    };

    const idToStr = (id: number) => {
        switch (id) {
            case 1: return "Owner";
            case 2: return "Admin";
            default: return "N/A";
        }
    };

    return (
        <div className="bg-[#2a2d3e] text-gray-100 min-h-screen p-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-8">Dashboard Access</h1>
                <button
                    onClick={handleInvitePopup}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center mb-8 transition duration-300"
                >
                    <UserPlus size={18} className="mr-2" />
                    Invite User
                </button>

                <div className="space-y-4">
                    {users.map((user) => (
                        <div key={user.discord_id} className="bg-[#212332] rounded-lg p-4 flex items-center justify-between shadow-lg">
                            <div className="flex items-center">
                                <img src={user.icon} alt={user.name} className="w-10 h-10 rounded-full mr-4" />
                                <div>
                                    <h3 className="font-semibold">{user.name}</h3>
                                    <p className="text-sm text-gray-400">{idToStr(user.permission)}</p>
                                </div>
                            </div>
                            {user.permission !== 1 && (
                                <button
                                    onClick={() => removeUser(user)}
                                    className="text-red-500 hover:text-red-600 transition duration-300"
                                >
                                    <Trash2 size={18} />
                                </button>
                            )}
                        </div>
                    ))}
                </div>

                {showPopup && ReactDOM.createPortal(
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-200">
                        <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                            <h2 className="text-xl font-bold mb-4">Invite User to Dashboard</h2>
                            <p className="mb-2">URL: <a href={inviteLink} className="text-blue-500 underline">{inviteLink}</a></p>
                            <p className="text-sm text-gray-400 mb-4">This link expires at {getExpirationDate()} and is only valid once.</p>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={handleInvitePopup}
                                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCopy}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
                                >
                                    <Copy size={18} className="mr-2" />
                                    {copyStatus}
                                </button>
                            </div>
                        </div>
                    </div>, document.body
                )}

                {errorMessage && (
                    <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded shadow-lg">
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardAccess;