import React from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
// import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Callback from "./pages/Callback";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import React Bootstrap CSS
import './styles/styles.css'
import Home from "./pages/Home";

function App() {
  return (

    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard defaultItem={"settings"} />} />
          <Route path="/settings" element={<Dashboard defaultItem={"settings"} />} />
          <Route path="/commands" element={<Dashboard defaultItem={"commands"} />} />
          <Route path="/updates" element={<Dashboard defaultItem={"updates"} />} />
          <Route path="/payment" element={<Dashboard defaultItem={"payment"} />} />
          <Route path="/ticket/logs" element={<Dashboard defaultItem={"ticket-logs"} />} />
          <Route path="/ticket/settings" element={<Dashboard defaultItem={"ticket-settings"} />} />
          <Route path="/monitors" element={<Dashboard defaultItem={"monitors"} />} />
          <Route path="/monitors/redirects" element={<Dashboard defaultItem={"monitors/redirects"} />} />
          <Route path="/monitors/:uuid" element={<Dashboard defaultItem={"monitors"} />} />
          <Route path="/monitors/:uuid/graph" element={<Dashboard defaultItem={"monitors"} />} />
          <Route path="/dashboard-access" element={<Dashboard defaultItem={"dashboard-access"} />} />
          <Route path="/customers" element={<Dashboard defaultItem={"customers"} />} />

          <Route path="/callback" element={<Callback />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
