import React, { useState } from "react";
import Select from 'react-select';
import { X, Plus, Save, Info } from "lucide-react";
import Button from '@mui/material/Button';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import { API_URL } from "../../constants";


interface Customer {
    next_renewal: string;
    image: string;
    hex_color: string;
    footer_text: string;
    purchases: object;
    name: string;
    footer_icon: string;
}

interface Bot {
    id: number;
    name: string;
    app_id: string;
    footer_text: string;
    whitelisted_servers: Array<string>;
    hex_color: String;
}

interface Server {
    label: string;
    value: string;
}

interface Props {
    botData: Bot;
    customerData: Customer;
    handleUpdateBot: () => void;
    handleLogOut: () => void;
    handleBlur: () => void;
}

const select_styles = {
    input: (provided) => ({
        ...provided,
        color: "white",
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: '#2a2d3e',
        borderColor: '#4a4d5e',
        color: '#ffffff',
        height: "2.8rem"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#2a2d3e',
        color: '#ffffff',
        maxHeight: '200px',
        overflowY: 'auto',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#3a3d4e' : '#2a2d3e',
        color: '#ffffff',
    }),
};

const Settings: React.FC<Props> = ({ customerData, botData, handleUpdateBot, handleLogOut, handleBlur }) => {
    const [image, setImage] = useState<string>(customerData.image);
    const [footerText, setFooterText] = useState<string>(botData.footer_text);
    const [footerIcon, setFooterIcon] = useState<string>(customerData.footer_icon);
    const [hex, setHex] = useState<string>(customerData.hex_color);
    const [botName, setBotName] = useState<string>(botData.name);
    const [customerName, setCustomerName] = useState<string>(customerData.name);
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [isServerModalOpen, setIsServerModalOpen] = useState<boolean>(false);
    const [newServerId, setNewServerId] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transformedServers, setTransformedServers] = useState<Server[]>(
        Array.isArray(botData.whitelisted_servers)
            ? botData.whitelisted_servers.map(server => ({ label: server, value: server }))
            : []
    );

    const handleRemoveServer = (label: string) => {
        setTransformedServers(transformedServers.filter(option => option.value !== label));
        setBtnDisabled(false);
    }

    const handleAddServerClick = () => {
        setIsServerModalOpen(true);
        handleBlur();
    }

    const handleServerModalClose = () => {
        setIsServerModalOpen(false);
        setNewServerId('');
        handleBlur();
    }

    const handleServerAdd = () => {
        if (newServerId.trim() !== "") {
            setTransformedServers(prevServers => [...prevServers, { label: newServerId, value: newServerId }]);
            setBtnDisabled(false);
        }
        handleServerModalClose();
    }

    const CustomOption = ({ innerProps, label }: { innerProps: any, label: string }) => (
        <div className="flex items-center justify-between px-3 py-2 hover:bg-[#3a3d4e] cursor-pointer" {...innerProps}>
            <span>{label}</span>
            <button onClick={(e) => { e.stopPropagation(); handleRemoveServer(label); }} className="text-red-500 hover:text-red-700">
                <X size={16} />
            </button>
        </div>
    );

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
        setBtnDisabled(false);
    };

    const handleHexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newHex = event.target.value;
        setHex(newHex);
        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/i;
        if (hexRegex.test(newHex)) {
            setErrorMessage(null);
            setBtnDisabled(false);
        } else {
            setErrorMessage("Invalid hex color value");
            setBtnDisabled(true);
        }
    };

    const showSuccess = () => toast.success('Successfully updated Bot! Use /update to update footer etc.', {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const showPending = () => toast.promise(
        new Promise(resolve => setTimeout(resolve, 5000)),
        {
            pending: 'Your bot image and name will be updated as fast as possible!',
        }
    );

    const updateCustomer = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/customer/update`, {
                bot_name: botName,
                customer_name: customerName,
                image_url: image,
                hex_color: hex,
                footer_text: footerText,
                footer_icon: footerIcon,
                app_id: botData.app_id,
                whitelisted_servers: transformedServers.map((serverData: Server) => serverData.value)
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setBtnDisabled(true);
                handleUpdateBot();
                showSuccess();
            } else if (response.status === 206) {
                showPending();
            }
        } catch (error) {
            handleLogOut();
        }
    }

    const checkValues = () => {
        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/i;
        if (!hexRegex.test(hex)) {
            setErrorMessage("Invalid hex color value");
            return;
        }
        if (!botName || !customerName || !image || !hex || !footerText || !footerIcon) {
            setErrorMessage("All fields must have a valid value.");
            return;
        }
        setErrorMessage(null);
        updateCustomer();
    }

    return (
        <div className="bg-[#2a2d3e] text-gray-100 min-h-screen p-8 scrollable">
            <ToastContainer />
            {/* Server Add Modal */}
            {isServerModalOpen && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">Add New Server</h3>
                        <input
                            type="text"
                            value={newServerId}
                            onChange={(e) => setNewServerId(e.target.value)}
                            placeholder="Enter server ID"
                            className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 mb-4 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleServerModalClose}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleServerAdd}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                Add Server
                            </button>
                        </div>
                    </div>
                </div>, document.body
            )}
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold mb-8">Settings</h1>
                <div className="grid gap-8 grid-cols-1 lg:grid-cols-3">
                    <div className="lg:col-span-2 space-y-8">
                        {/* Customer Settings */}
                        <div className="bg-[#212332] rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-semibold mb-6">Customer Settings</h2>
                            <div className="grid gap-6 md:grid-cols-2">
                                <div>
                                    <label htmlFor="customer_name" className="block text-sm font-medium mb-2">Name</label>
                                    <input
                                        type="text"
                                        id="customer_name"
                                        className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        onChange={handleInputChange(setCustomerName)}
                                        value={customerName}
                                        placeholder="Your Customer Name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="footer_icon" className="block text-sm font-medium mb-2">Footer Icon</label>
                                    <input
                                        type="text"
                                        id="footer_icon"
                                        className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        onChange={handleInputChange(setFooterIcon)}
                                        value={footerIcon}
                                        placeholder="Icon URL"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="bot_image" className="block text-sm font-medium mb-2 flex items-center">
                                        Image
                                        <div className="relative ml-2 group">
                                            <Info size={16} className="text-gray-400 cursor-help" />
                                            <span className="absolute hidden group-hover:block bg-[#3a3d4e] text-white text-xs rounded p-2 -mt-2 ml-6 w-48 z-10">
                                                Modifications to the "Bot Name" and "Image" fields might not reflect instantaneously.
                                            </span>
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        id="bot_image"
                                        className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        onChange={handleInputChange(setImage)}
                                        value={image}
                                        placeholder="Image URL"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="embedColor" className="block text-sm font-medium mb-2">Embed Hex Color</label>
                                    <div className="flex space-x-2">
                                        <input
                                            type="text"
                                            id="bot_hex"
                                            name="bot_hex"
                                            value={hex}
                                            className="flex-grow bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                            onChange={handleHexChange}
                                            required
                                        />
                                        <input
                                            type="color"
                                            value={hex}
                                            onChange={handleHexChange}
                                            className="h-10 w-10 rounded cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Bot Settings */}
                        <div className="bg-[#212332] rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-semibold mb-6">Bot Settings</h2>
                            <div className="grid gap-6 md:grid-cols-2">
                                <div>
                                    <label htmlFor="bot_name" className="block text-sm font-medium mb-2 flex items-center">
                                        Bot Name
                                        <div className="relative ml-2 group">
                                            <Info size={16} className="text-gray-400 cursor-help" />
                                            <span className="absolute hidden group-hover:block bg-[#3a3d4e] text-white text-xs rounded p-2 -mt-2 ml-6 w-48 z-10">
                                                Modifications to the "Bot Name" and "Image" fields might not reflect instantaneously.
                                            </span>
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        id="bot_name"
                                        className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        onChange={handleInputChange(setBotName)}
                                        value={botName}
                                        placeholder="Toolbox"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="footer_text" className="block text-sm font-medium mb-2">Footer Text</label>
                                    <input
                                        type="text"
                                        id="footer_text"
                                        className="w-full bg-[#2a2d3e] text-white rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        onChange={handleInputChange(setFooterText)}
                                        value={footerText}
                                        placeholder="Text"
                                        required
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="whitelisted_servers" className="block text-sm font-medium mb-2">Whitelisted Servers</label>
                                    <div className="flex space-x-2">
                                        <Select
                                            className="flex-grow"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            options={transformedServers}
                                            styles={select_styles}
                                            menuPlacement="top"
                                            menuPosition="fixed"
                                            menuShouldScrollIntoView={false}
                                            isOptionDisabled={() => true}
                                            components={{ Option: CustomOption }}
                                            placeholder="Select servers..."
                                            value={null}
                                        />
                                        <Button
                                            variant="contained"
                                            className="!bg-blue-500 !text-white h-10 min-w-[40px] !p-0"
                                            onClick={handleAddServerClick}
                                        >
                                            <Plus size={20} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Preview */}
                    <div className="rounded-lg p-6 flex flex-col" style={{ minHeight: '500px' }}>
                        <div className="flex-grow flex items-center justify-center">
                            <div className="rounded-lg p-4 w-full max-w-xl"> {/* Increased max-width here */}
                                <h2 className="text-3xl font-bold mb-6">Preview</h2>
                                <div className="w-full mx-auto">
                                    {/* Bot name and App tag */}
                                    <div className="flex items-center mb-4">
                                        <img src={image} alt="bot icon" className="w-8 h-8 mr-2 rounded-full" /> {/* Slightly larger icon */}
                                        <span className="text-white font-medium mr-2 text-lg">{botName}</span> {/* Larger text */}
                                        <span className="bg-[#3a3d4e] text-white text-xs px-2 py-1 rounded">App</span> {/* Added some padding */}
                                    </div>

                                    {/* Discord-like embed */}
                                    <div className="flex">
                                        {/* Left color bar */}
                                        <div
                                            className="flex-1 bg-[#2f3136] rounded p-6"
                                            style={{
                                                borderColor: hex || '#FF5733',
                                                borderLeftWidth: '4px',
                                                borderStyle: 'solid'
                                            }}
                                        >
                                            <div className="text-white font-bold mb-3 text-xl">Example</div> {/* Larger title */}
                                            <div className="mb-4"> {/* Increased margin */}
                                                <div className="text-white font-semibold text-lg">Test name</div> {/* Larger text */}
                                                <div className="text-gray-300 text-base">Test Value</div> {/* Adjusted text size */}
                                            </div>

                                            {/* Footer */}
                                            <div className="flex items-center mt-6 text-gray-400 text-sm"> {/* Increased top margin */}
                                                {footerIcon && (
                                                    <img src={footerIcon} alt="Footer Icon" className="w-5 h-5 mr-2 rounded-full" />
                                                )}
                                                <span>{footerText}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="submit"
                className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center transition-colors duration-200 z-10 disabled:bg-gray-400 disabled:cursor-not-allowed"
                disabled={btnDisabled}
                onClick={checkValues}
            >
                <Save size={18} className="mr-2" /> Save Changes
            </button>

            {errorMessage && (
                <div className="fixed bottom-20 right-8 bg-red-500 text-white px-4 py-2 rounded-lg">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default Settings;
