import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowLeft, Save, Trash2, Edit2, XCircle, Plus, Search } from 'lucide-react';
import ReactDOM from 'react-dom';

import { CustomToastContainer } from '../CustomCoastContainer';
import { toast } from 'react-toastify';
import { API_URL } from '../../../constants';



interface KeywordList {
    id: number;
    name: string;
    keywords: string[];
}

interface KeywordSettingsProps { }

const KeywordSettings: React.FC<KeywordSettingsProps> = () => {
    const [keywords, setKeywords] = useState<KeywordList[]>([]);
    const [scrape, setScrape] = useState<boolean>(false);
    const [newKeywordList, setNewKeywordList] = useState<KeywordList>({ id: -1, name: "", keywords: [] });
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingKeywordList, setEditingKeywordList] = useState<KeywordList | null>(null);

    useEffect(() => {
        setScrape(true);
    }, []);

    useEffect(() => {
        if (scrape) {
            fetchKeywordData();
        }
    }, [scrape]);

    // Fetch keyword data
    const fetchKeywordData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/keywords`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setKeywords(response.data);
            }
        } catch (error) {
            console.error('Error fetching keyword data:', error);
        }
    };

    // Save a keyword list
    const saveKeywordList = async (keywordList: KeywordList) => {
        const toastId = toast.loading("Saving keyword list...");
        try {
            const response = await axios.put(`${API_URL}/v1/keywords`, {
                keywords: keywordList.keywords,
                keywordListID: keywordList.id,
                name: keywordList.name,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Keywords updated successfully');
                toast.update(toastId, { render: "Keyword list saved successfully", type: "success", isLoading: false, autoClose: 3000 });

                fetchKeywordData();
            }
        } catch (error) {
            console.error('Failed to update keywords', error);
            toast.update(toastId, { render: "Failed to save keyword list", type: "error", isLoading: false, autoClose: 3000 });

        }
    };

    // Delete a keyword list
    const deleteKeywordList = async (keywordListID: number) => {
        const toastId = toast.loading("Deleting keyword list...");
        try {
            const response = await axios.delete(`${API_URL}/v1/keywords`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { keywordListID: keywordListID }
            });

            if (response.status === 200) {
                console.log('Keyword list deleted successfully');
                setKeywords(prev => prev.filter(k => k.id !== keywordListID));
                toast.update(toastId, { render: "Keyword list deleted successfully", type: "success", isLoading: false, autoClose: 3000 });

            }
        } catch (error) {
            console.error('Failed to delete keyword list', error);
            toast.update(toastId, { render: "Failed to delete keyword list", type: "error", isLoading: false, autoClose: 3000 });

        }
    };

    // Create a new keyword list
    const createNewKeywordList = async () => {
        const toastId = toast.loading("Creating new keyword list...");
        try {
            const response = await axios.post(`${API_URL}/v1/keywords`, {
                keywords: newKeywordList.keywords,
                name: newKeywordList.name,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Keyword list created successfully');
                setShowPopup(false);

                const updatedKeywordList = {
                    ...newKeywordList,
                    id: response.data.data.new_id
                };
                console.log(updatedKeywordList);

                setKeywords((prevKeywords) => [
                    ...prevKeywords,
                    updatedKeywordList
                ]);

                setNewKeywordList({ id: -1, name: "", keywords: [] });
                toast.update(toastId, { render: "New keyword list created successfully", type: "success", isLoading: false, autoClose: 3000 });

            }
        } catch (error) {
            console.error('Failed to create keyword list', error);
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewKeywordList((prevState) => ({
            ...prevState,
            name: event.target.value
        }));
    };

    const handleKeywordsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const keywordArray = event.target.value.split('\n').filter(keyword => keyword.trim() !== '');
        setNewKeywordList((prevState) => ({
            ...prevState,
            keywords: keywordArray
        }));
    };

    useEffect(() => {
        const appDiv = document.querySelector('.App');
        if ((showPopup || editingKeywordList) && appDiv) {
            appDiv.classList.add('blur_bg');
        } else if (appDiv) {
            appDiv.classList.remove('blur_bg');
        }

        // Cleanup function in case component unmounts
        return () => {
            if (appDiv) {
                appDiv.classList.remove('blur_bg');
            }
        };
    }, [showPopup, editingKeywordList]);

    const openEditDialog = (keywordList: KeywordList) => {
        setEditingKeywordList(keywordList);
    };

    const filteredKeywordLists = keywords.filter(keyword =>
        keyword.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="mb-8 bg-[#2a2d3e] text-gray-100 p-8 sm:p-4 keyword-container">
            <CustomToastContainer />
            <div className="mx-auto">
                <h1 className="text-3xl font-bold mb-8">Keyword Settings</h1>


                <div className="dark-container p-4 sm:p-6 rounded-lg mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Keywords</h3>
                        <button
                            onClick={() => setShowPopup(true)}
                            className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded flex items-center"
                        >
                            <Plus size={18} className="mr-2" /> Create New
                        </button>
                    </div>

                    <div className="relative w-full sm:w-64 mb-4">
                        <input
                            type="text"
                            placeholder="Search Keyword Lists..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-[#2a2d3e] text-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200 w-full"
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Search size={18} className="text-gray-500" />
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                <tr>
                                    <th className="px-2 sm:px-4 py-2 text-left">Name</th>
                                    <th className="px-2 sm:px-4 py-2 text-left">Keywords</th>
                                    <th className="px-2 sm:px-4 py-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredKeywordLists.map((keywordList) => (
                                    <tr key={keywordList.id} className="border-b border-gray-700">
                                        <td className="px-2 sm:px-4 py-2 font-medium">{keywordList.name}</td>
                                        <td className="px-2 sm:px-4 py-2">
                                            {keywordList.keywords.length} keywords
                                        </td>
                                        <td className="px-2 py-2 w-24">
                                            <div className="flex justify-center space-x-2">
                                                <button
                                                    onClick={() => openEditDialog(keywordList)}
                                                    className="p-1 rounded bg-yellow-600 hover:bg-yellow-700"
                                                    title="Edit"
                                                >
                                                    <Edit2 size={16} />
                                                </button>
                                                <button
                                                    onClick={() => deleteKeywordList(keywordList.id)}
                                                    className="p-1 rounded bg-red-600 hover:bg-red-700"
                                                    title="Delete"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showPopup && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-150">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">Create New Keyword List</h2>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium mb-1">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    value={newKeywordList.name}
                                    onChange={handleNameChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                    placeholder="Enter keyword list name"
                                />
                            </div>
                            <div>
                                <label htmlFor="keywords" className="block text-sm font-medium mb-1">Keywords</label>
                                <textarea
                                    id="keywords"
                                    defaultValue={newKeywordList.keywords.join('\n')}
                                    onChange={handleKeywordsChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded h-40"
                                    placeholder="Enter keywords (one per line)"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex justify-center space-x-4">
                            <button
                                onClick={() => setShowPopup(false)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                            >
                                <XCircle size={18} className="mr-2" />
                                Cancel
                            </button>
                            <button
                                onClick={createNewKeywordList}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                            >
                                <Save size={18} className="mr-2" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                , document.body)}

            {editingKeywordList && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-150">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">Edit Keyword List</h2>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="editName" className="block text-sm font-medium mb-1">Name</label>
                                <input
                                    id="editName"
                                    type="text"
                                    value={editingKeywordList.name}
                                    onChange={(e) => setEditingKeywordList({ ...editingKeywordList, name: e.target.value })}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                />
                            </div>
                            <div>
                                <label htmlFor="editKeywords" className="block text-sm font-medium mb-1">Keywords</label>
                                <textarea
                                    id="editKeywords"
                                    defaultValue={editingKeywordList.keywords.join('\n')}
                                    onChange={(e) => setEditingKeywordList({ ...editingKeywordList, keywords: e.target.value.split('\n').filter(keyword => keyword.trim() !== '') })}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded h-40"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex justify-center space-x-4">
                            <button
                                onClick={() => setEditingKeywordList(null)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                            >
                                <XCircle size={18} className="mr-2" />
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    saveKeywordList(editingKeywordList);
                                    setEditingKeywordList(null);
                                }}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                            >
                                <Save size={18} className="mr-2" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                , document.body)}
        </div>
    );
};

export default KeywordSettings;