import Select, { components } from 'react-select';
import "../../styles/settings_tickets_styles.css"
import React, { useState, useEffect } from "react";
import Collapsible from '../Other/Collapsible';
import DropdownPanel from '../Other/DropdownPanel';
import Field from '../Other/Field';
import Option from '../Other/Option';
import axios from 'axios';
import { log } from 'console';
import { API_URL } from '../../constants';




interface ServerState {
    icon: string;
    id: string;
    name: string;
}
interface ChannelState {
    icon: string;
    id: string;
    name: string;
}
interface BotData {
    id: number,
    name: string,
    image: string,
    hex_color: string,
    footer_text: string,
    footer_icon: string,
    app_id: string
}
interface Roles {
    id: string;
    name: string;
    color: number;
}

interface TicketsProps {
    handleLogOut: () => void;
    name: string;
    image: string;
    hex: string;
    footer_icon: string;
    footer_text: string;
    app_id: string
}
interface FieldProps {
    name: string;
    value: string;
    inline: boolean;
}
interface OptionProps {
    emoji: string;
    description: string;
    name: string;
}

const customStyles_server = {
    input: (provided: any) => ({
        ...provided,
        color: "white",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '170px', // Change border color
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px', // Change font size
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white', // Add text color
        '&:active': {
            backgroundColor: '#f2f2f2', // Add background color for active state
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd', // Change background color on hover
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },

    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),

};
const customStyles_panel = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '170px', // Change border color
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px', // Change font size
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white', // Add text color
        '&:active': {
            backgroundColor: '#f2f2f2', // Add background color for active state
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd', // Change background color on hover
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },

    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),

};
const OptionWithImage = (props: any) => {
    return (
        <components.Option {...props}>
            <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.Option>
    );
};
const SingleValueWithImage = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.SingleValue>
    );
};


const Tickets: React.FC<TicketsProps> = ({ handleLogOut, name, image, hex, footer_icon, footer_text, app_id }) => {
    const [type, setType] = useState("")
    const [scraped, setScraped] = useState(false)
    const [guilds, setGuilds] = useState([])
    const [channels, setChannels] = useState<ChannelState[]>([])
    const [selected_roles, set_selected_roles] = useState<Roles[]>([]);
    const [roles, set_roles] = useState<Roles[]>([]);
    const [fetched_roles, set_fetched_roles] = useState<String[]>([])




    // embed values
    const [title, set_title] = useState("")
    const [description, set_description] = useState("")
    const [placeholder, set_placeholder] = useState("")
    const [fields, set_fields] = useState<FieldProps[]>([]);
    const [options, setOptions] = useState<OptionProps[]>([]);
    const [thumbnail_url, set_thumbnail_url] = useState("")
    const [banner_url, set_banner_url] = useState("")

    const panel_options = [
        { value: 'dropdown', label: 'Dropdown' },
        { value: 'button', label: 'Buttons' },
    ];
    const [server, setServer] = useState<ServerState | {}>({});
    const [channel, setChannel] = useState(null);


    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_title(event.target.value);
    };
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_description(event.target.value);
    };

    const handle_selected_roles = (selected) => {
        console.log(selected)
        set_selected_roles(selected); // Update the selected options in state
    };

    const get_guild_roles = async () => {
        try {
            const serverId = ('id' in server) ? (server as ServerState).id : '';

            if (serverId !== '') {
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${serverId}/roles`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    set_roles(response.data.data.bots[0].roles)
                }
            }
        } catch (error) {
            handleLogOut();
        }

    }


    const handle_change_server = (selectedOption: any) => {
        setServer(selectedOption);
        console.log(selectedOption)
        // get roles
        get_guild_roles();

    };
    const handle_change_channel = (selectedOption: any) => {
        setChannel(selectedOption);
    };
    const handleChangePanel = (selectedOption: any) => {
        setType(selectedOption.value);
    };
    const handlePlaceHolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_placeholder(event.target.value);
    };
    const handleThumbnailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_thumbnail_url(event.target.value);
    };
    const handleBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_banner_url(event.target.value);
    };

    const handleFieldNameChange = (name: string, id: number) => {
        const temp: FieldProps[] = [...fields];
        temp[id].name = name
        set_fields(temp)
    };
    const handleFieldValueChange = (value: string, id: number) => {
        const temp: FieldProps[] = [...fields];
        temp[id].value = value
        set_fields(temp)
    };
    const handleInlineChange = (value: boolean, id: number) => {
        const temp: FieldProps[] = [...fields];
        temp[id].inline = value
        set_fields(temp)
    };
    const deleteField = (id: number) => {
        const temp: FieldProps[] = [...fields];
        temp.splice(id, 1);
        set_fields(temp)
    }
    const deleteOption = (id: number) => {
        const temp: OptionProps[] = [...options];
        temp.splice(id, 1);
        setOptions(temp)
    }

    const add_new_field = () => {
        const new_field: FieldProps = {
            name: "",
            value: "",
            inline: false
        };
        set_fields((prevFields) => [...prevFields, new_field]);
    };
    const add_new_option = () => {
        const new_option: OptionProps = {
            name: "",
            description: "",
            emoji: ""
        };
        setOptions((prevOptions) => [...prevOptions, new_option]);
    };
    const handleOptionNameChange = (name: string, id: number) => {
        const temp: OptionProps[] = [...options];
        temp[id].name = name
        setOptions(temp)
    };
    const handleOptionEmojiChange = (emoji: string, id: number) => {
        const temp: OptionProps[] = [...options];
        temp[id].emoji = emoji
        setOptions(temp)
    };
    const handleOptionDescriptionChange = (description: string, id: number) => {
        const temp: OptionProps[] = [...options];
        temp[id].description = description
        setOptions(temp)
    };

    const get_bot_guilds = async () => {
        try {


            const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                setGuilds(response.data.data.bots[0].guilds)

            }
        } catch (error) {
            handleLogOut();
        }

    }

    const send_panel = async () => {
        try {


            const response = await axios.post(`${API_URL}/v1/update/bot/ticket/panel`, {
                app_id: app_id,
                title: title,
                description: description,
                placeholder: placeholder,
                fields: fields,
                options: options,
                thumbnail_url: thumbnail_url,
                banner_url: banner_url,
                roles: selected_roles

            }, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                console.log("Updated ticket panel!")
            }
            else if (response.status === 206) {
                console.log("Not updated ticket panel!")
            }
        }
        catch {
            handleLogOut()
        }
    }
    const get_panel_data = async () => {
        try {

            const serverId = ('id' in server) ? (server as ServerState).id : '';
            const response = await axios.get(`${API_URL}/v1/user/info/bot/ticket/panel`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {

                //setChannels(response.data.data.bots[0].channels)
                const data = response.data.data.panel_data
                set_title(data.title)
                set_fields(data.fields)
                set_description(data.description)
                set_placeholder(data.placeholder)
                setOptions(data.options)
                set_thumbnail_url(data.thumbnail_url)
                set_banner_url(data.banner_url)
                set_fetched_roles(response.data.data.roles);


            }
        } catch (error) {
            handleLogOut();
        }
    }

    useEffect(() => {
        setScraped(true)
    }, []);
    useEffect(() => {
        if (scraped) {
            // get panel data
            get_panel_data()
            get_bot_guilds()
        }
    }, [scraped]);

    useEffect(() => {
        setChannel(null)
        if (Object.keys(server).length !== 0) {
            get_guild_roles()
        }
    }, [server]);
    useEffect(() => {
        console.log("--")
        if (roles.length > 0) {
            const updatedSelectedRoles = roles
                .filter(role => fetched_roles.includes(role.id))
                .map(role => ({
                    ...role,
                    label: role.name, // Assuming each role has a 'name' attribute
                    value: role.name
                }));

            // Update the state
            set_selected_roles(updatedSelectedRoles);
            console.log(updatedSelectedRoles)
        }
    }, [roles])
    const roleWithLabel = roles.map((role) => ({
        ...role,
        value: role.id,
        label: role.name,
    }));






    const dropdown_choices_add = (
        <div>
            <div>
                <p className='text-xl'>Guilds</p>
                <Select
                    className="server_dropdown mb-2"
                    classNamePrefix="select"
                    isSearchable={true}
                    options={guilds}
                    styles={customStyles_server}
                    onChange={handle_change_server}
                    components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                    placeholder="Select Server"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#509dfd',
                        },
                    })}

                />
                {Object.keys(server).length !== 0 ?
                    <>
                        <label>Roles<div className="info-container">
                            <span className="ml-1 rounded-full inline-flex items-center justify-center border border-gray-400 w-4 h-4 info info-name">
                                <span className="text-black font-bold text-l">i</span>
                            </span>

                            <span className="info-tooltip">Add the roles you want to mention in the new ticket</span>
                        </div>:

                        </label>
                        <Select
                            classNamePrefix="role"
                            isSearchable={true}
                            options={roleWithLabel}
                            styles={customStyles_server}
                            value={selected_roles}
                            onChange={handle_selected_roles}
                            isMulti
                            placeholder="Select Role"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#509dfd',
                                },
                            })}
                        />
                    </>
                    : null
                }


            </div>
            <div>
                <p className='text-xl'>Embed</p>
                <label htmlFor="title"
                    className="base_input_text">Title</label>
                <input type="text" id="title_text"
                    maxLength={45}
                    className="base_input"
                    defaultValue={title}
                    onChange={handleTitleChange}
                    placeholder="Text" required />
            </div>
            <div>
                <label htmlFor="description"
                    className="base_input_text">Description</label>
                <input type="text" id="description"
                    className="base_input"
                    defaultValue={description}
                    onChange={handleDescriptionChange}
                    placeholder="Text" required />
            </div>

            <div>
                <label htmlFor="thumbnail_url"
                    className="base_input_text">Thumbnail URL</label>
                <input type="text" id="thumbnail_url"
                    className="base_input"
                    defaultValue={thumbnail_url}
                    onChange={handleThumbnailChange}
                    placeholder="Text" required />
            </div>
            <div>
                <label htmlFor="banner_url"
                    className="base_input_text">Banner URL</label>
                <input type="text" id="banner_url"
                    className="base_input"
                    defaultValue={banner_url}
                    onChange={handleBannerChange}
                    placeholder="Text" required />
            </div>
            <div>
                <label htmlFor="placeholder"
                    className="base_input_text">Placeholder</label>
                <input type="text" id="placeholder"
                    className="base_input"
                    maxLength={45}
                    defaultValue={placeholder}
                    onChange={handlePlaceHolderChange}
                    placeholder="Your Placeholder" required />
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-l'>Fields</p>
                <button
                    className="submit_btn text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce-once ml-px m-2"
                    onClick={add_new_field}
                >
                    Add field
                </button>
            </div>


            <div>
                {fields.map((item, index) => {
                    return (
                        <Field title={`Field ${index}`} id={index} handleFieldNameChange={handleFieldNameChange} handleFieldValueChange={handleFieldValueChange} fields={fields} deleteField={deleteField} handleInlineChange={handleInlineChange} />
                    )
                }
                )}
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-l'>Dropdown</p>
                <button
                    className="submit_btn text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce-once ml-px m-2"
                    onClick={add_new_option}

                >Add option</button>
            </div>
            <div>
                {options.map((item, index) => {
                    return (
                        <Option title={`Option ${index}`} option={item} id={index} handleOptionDescriptionChange={handleOptionDescriptionChange} handleOptionEmojiChange={handleOptionEmojiChange} handleOptionNameChange={handleOptionNameChange} deleteOption={deleteOption} />
                    )
                }
                )}
            </div>
        </div >

    )

    return (
        <div>
            <div className="scrollable_responsive">

                <div>
                    <p className='text-xl'>Panel Settings</p>
                    <div className='grid md:grid-cols-2 grid-cols-1'>
                        <div className='scrollable panel-settings'>


                            {dropdown_choices_add}

                            <button type="submit"
                                className="submit_btn text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce-once ml-px"
                                // disabled={btn_disabled}
                                onClick={send_panel}
                            >

                                Save Panel
                            </button >
                        </div>
                        <div className='rounded scrollable ticket_preview '>
                            <div>Preview</div>

                            <DropdownPanel title={title} thumbnail_url={thumbnail_url} banner_url={banner_url} placeholder={placeholder} fields={fields} options={options} name={name} image={image} hex={hex} footer_icon={footer_icon} footer_text={footer_text} description={description} />



                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Tickets;
