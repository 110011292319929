import React, { useState, useEffect } from 'react';
import "../../styles/no_premium_styles.css"
import axios from 'axios';
import { API_URL } from '../../constants';


interface NoPremiumProps {
    username: string;
}

const NoPremiumPage: React.FC<NoPremiumProps> = ({ username }) => {
    const [scraped, setScraped] = useState(false)

    const validate_key = async (key) => {
        try {
            const response = await axios.post(`${API_URL}/v1/validate/bot/invite`, {
                key: key,

            }, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                if (!response.data.data.error) {
                    window.location.search = '';
                    window.location.reload();

                }
            }

        }
        catch {

        }
    }

    useEffect(() => {
        setScraped(true)
    }, []);
    useEffect(() => {
        if (scraped) {
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);

            const key = searchParams.get('key');
            const type = searchParams.get('type');

            if (key && type === "invite") {
                // 'key' and 'type' parameters are present in the URL
                console.log(`Key: ${key}, Type: ${type}`);
                validate_key(key);
            }


        }
    }, [scraped]);


    return (
        <div className="premium-container">
            <div className="premium-card">
                <h2 className="card-title">Hey, {username}!</h2>
                <p className="card-message">You're currently missing out on premium features.</p>
                <a href="https://discord.gg/ZTqv2QcZnn" target="_blank" rel="noopener noreferrer" className="discord-link">
                    Join our Discord server for premium access
                </a>
            </div>
        </div>
    );
};


export default NoPremiumPage;
