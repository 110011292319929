import React, { useEffect, useRef, useState } from 'react';
import { Calendar, Monitor, Wrench, CreditCard } from 'lucide-react';

interface Purchase {
    monitor: string[];
    ticket: string[];
    toolbox: string[];
}

interface CustomerData {
    footer_icon: string;
    hex_color: string;
    image: string;
    name: string;
    next_renewal: string;
    purchases: Purchase;
}

interface PaymentViewProps {
    customerData: CustomerData;
}

const PaymentView: React.FC<PaymentViewProps> = ({ customerData }) => {
    const toolboxRef = useRef<HTMLDivElement>(null);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const handleStripePayment = () => {
        // Placeholder for future Stripe integration
        console.log('Stripe payment button clicked');
        alert('Stripe integration not yet implemented');
    };


    return (
        <div className='monitor-container scrollable'>
            <div className="bg-[#2a2d3e] text-gray-100 min-h-screen p-8 ">
                <div className="max-w-4xl mx-auto">
                    <h1 className="text-3xl font-bold mb-8">Payment Information</h1>

                    <div className="bg-[#212332] rounded-lg p-6 shadow-lg mb-8">
                        <div className="flex flex-col md:flex-row items-center justify-between mb-6">
                            <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
                                <img src={customerData.image} alt={customerData.name} className="w-24 h-24 rounded-full mb-4 md:mb-0 md:mr-6" />
                                <div>
                                    <h2 className="text-2xl font-bold mb-2" style={{ color: customerData.hex_color }}>{customerData.name}</h2>
                                    <p className="text-gray-400 flex items-center">
                                        <Calendar className="mr-2" size={18} />
                                        Next Renewal: {formatDate(customerData.next_renewal)}
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={handleStripePayment}
                                className="bg-[#635bff] hover:bg-[#4f46e5] text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
                            >
                                <CreditCard className="mr-2" size={18} />
                                Pay with Stripe
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-[#212332] rounded-lg p-6 shadow-lg">
                            <h3 className="text-xl font-semibold mb-4 flex items-center">
                                <Monitor className="mr-2" size={24} />
                                Purchased Monitors
                            </h3>
                            <ul className="space-y-2">
                                {customerData.purchases.monitor.map((item, index) => (
                                    <li key={index} className="text-gray-300 capitalize flex items-center">
                                        <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div
                            ref={toolboxRef}
                            className="bg-[#212332] rounded-lg p-6 shadow-lg transition-all duration-300 ease-in-out"
                        >

                            <h3 className="text-xl font-semibold mb-4 flex items-center">
                                <Wrench className="mr-2" size={24} />
                                Purchased Toolbox
                            </h3>
                            <ul className="space-y-2">

                                {customerData.purchases.toolbox && customerData.purchases.toolbox.map((item, index) => (
                                    <li key={index} className="text-gray-300 capitalize flex items-center">
                                        <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentView;