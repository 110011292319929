import React, { useMemo } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
    ResponsiveContainer, Legend
} from 'recharts';
import { format } from 'date-fns';
import { StatusCode } from '../../../../types';



interface MultiStatusCodeGraphProps {
    data: StatusCode[];
}

const statusCodeColors: { [key: string]: string } = {
    '200': '#10B981',
    '301': '#3B82F6',
    '400': '#F59E0B',
    '403': '#EF4444',
    '404': '#F97316',
    '406': '#FBBF24',
    '500': '#6B7280',
    '503': '#9CA3AF',
};

const getStatusCodeColor = (statusCode: string): string => {
    return statusCodeColors[statusCode] || '#CBD5E0';
};

const getStatusCodeName = (statusCode: string): string => {
    const knownCodes: { [key: string]: string } = {
        '200': '200 OK',
        '301': '301 Moved Permanently',
        '400': '400 Bad Request',
        '403': '403 Forbidden',
        '404': '404 Not Found',
        '406': '406 Not Acceptable',
        '500': '500 Internal Server Error',
        '503': '503 Service Unavailable',
    };
    return knownCodes[statusCode] || `Status ${statusCode}`;
};

const MultiStatusCodeGraph: React.FC<MultiStatusCodeGraphProps> = ({ data }) => {
    const uniqueStatusCodes: string[] = useMemo(() => {
        const codes = new Set<string>();
        data.forEach((entry) => {
            if (entry.counts) {
                Object.keys(entry.counts).forEach((code) => {
                    codes.add(code);
                });
            }
        });
        return Array.from(codes).sort((a, b) => parseInt(a) - parseInt(b));
    }, [data]);

    if (!data || data.length === 0) {
        return <div>No data available to display.</div>;
    }

    return (
        <div className="w-full h-[600px] rounded-lg shadow-lg p-6 mt-4">
            <h2 className="text-2xl font-bold mb-6 text-gray-100">Status Codes Over Time</h2>
            <ResponsiveContainer width="100%" height="90%">
                <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis
                        dataKey="time"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        scale="time"
                        tickFormatter={(time) => format(new Date(time), 'HH:mm')}
                        stroke="#9CA3AF"
                        tick={{ fill: '#9CA3AF', fontSize: 12 }}
                        axisLine={{ stroke: '#4B5563' }}
                        tickLine={{ stroke: '#4B5563' }}
                    />
                    <YAxis
                        stroke="#9CA3AF"
                        tick={{ fill: '#9CA3AF', fontSize: 12 }}
                        axisLine={{ stroke: '#4B5563' }}
                        tickLine={{ stroke: '#4B5563' }}
                        allowDecimals={false}
                        label={{ value: 'Count', angle: -90, position: 'insideLeft', fill: '#9CA3AF' }}
                    />
                    <Tooltip
                        labelFormatter={(time) => format(new Date(time), 'yyyy-MM-dd HH:mm')}
                        contentStyle={{
                            background: 'rgba(17, 24, 39, 0.8)',
                            border: '1px solid #4B5563',
                            borderRadius: '6px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            color: '#E5E7EB'
                        }}
                        formatter={(value, name) => [value, getStatusCodeName(name as string)]}
                    />
                    <Legend
                        formatter={(value) => {
                            const statusCode = value;
                            return (
                                <span style={{ color: getStatusCodeColor(statusCode) }}>
                                    {getStatusCodeName(statusCode)}
                                </span>
                            );
                        }}
                    />
                    {uniqueStatusCodes.map((statusCode) => (
                        <Line
                            key={statusCode}
                            type="stepAfter"
                            dataKey={`counts.${statusCode}`}
                            name={statusCode}
                            stroke={getStatusCodeColor(statusCode)}
                            strokeWidth={2}
                            dot={false}
                            activeDot={{ r: 6, strokeWidth: 2 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default MultiStatusCodeGraph;
