import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Save, Trash2, Edit2, XCircle, Plus, Search, ChevronDown, Check, X } from 'lucide-react';
import ReactDOM from 'react-dom';
import { API_URL } from '../../../constants';



interface Redirect {
    id: number;
    webhook: string;
    keyword: string;
    type: string;
    active: boolean;
}

interface MonitorRedirectProps { }

const MonitorRedirect: React.FC<MonitorRedirectProps> = () => {
    const [redirects, setRedirects] = useState<Redirect[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingRedirect, setEditingRedirect] = useState<Redirect | null>(null);
    const [newRedirect, setNewRedirect] = useState<Redirect>({
        id: -1,
        webhook: "",
        keyword: "",
        type: "Name",
        active: true
    });

    useEffect(() => {
        fetchRedirectData();
    }, []);

    useEffect(() => {
        const appDiv = document.querySelector('.App');
        if ((showPopup) && appDiv) {
            appDiv.classList.add('blur_bg');
        } else if (appDiv) {
            appDiv.classList.remove('blur_bg');
        }

        return () => {
            if (appDiv) {
                appDiv.classList.remove('blur_bg');
            }
        };
    }, [showPopup]);

    const fetchRedirectData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/redirects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setRedirects(response.data.data.redirects);
            }
        } catch (error) {
            console.error('Error fetching redirect data:', error);
        }
    };

    const saveRedirect = async (redirect: Redirect) => {
        try {
            const response = await axios.put(`${API_URL}/v1/redirects`, {
                redirect: redirect,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Redirect updated successfully');
                fetchRedirectData();
            }
        } catch (error) {
            console.error('Failed to update redirect', error);
        }
    };

    const deleteRedirect = async (redirectId: number) => {
        try {
            const response = await axios.delete(`${API_URL}/v1/redirects`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { id: redirectId }
            });

            if (response.status === 200) {
                console.log('Redirect deleted successfully');
                setRedirects(prev => prev.filter(r => r.id !== redirectId));
            }
        } catch (error) {
            console.error('Failed to delete redirect', error);
        }
    };

    const createNewRedirect = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/redirects`, {
                redirect: newRedirect,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Redirect created successfully');
                setShowPopup(false);
                fetchRedirectData();
                setNewRedirect({
                    id: -1,
                    webhook: "",
                    keyword: "",
                    type: "Name",
                    active: true
                });
            }
        } catch (error) {
            console.error('Failed to create redirect', error);
        }
    };

    const toggleActive = (redirect: Redirect) => {
        const updatedRedirect = { ...redirect, active: !redirect.active };
        setRedirects(prevRedirects =>
            prevRedirects.map(r => r.id === redirect.id ? updatedRedirect : r)
        );
        saveRedirect(updatedRedirect);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (editingRedirect) {
            setEditingRedirect({
                ...editingRedirect,
                [name]: value
            });
        } else {
            setNewRedirect(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const openEditDialog = (redirect: Redirect) => {
        setEditingRedirect(redirect);
        setShowPopup(true);
    };

    const filteredRedirects = redirects.filter(redirect =>
        redirect.keyword.toLowerCase().includes(searchTerm.toLowerCase()) ||
        redirect.webhook.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const redirectTypes = ["Name", "SKU"];

    return (
        <div className="mb-4 bg-[#2a2d3e] text-gray-100 p-2 sm:p-4 redirect-container rounded">
            <div className="mx-auto">
                <h1 className="text-3xl font-bold mb-8">Monitor Redirects</h1>


                <div className="dark-container p-4 sm:p-6 rounded-lg mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Redirects</h3>
                        <button
                            onClick={() => setShowPopup(true)}
                            className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded flex items-center"
                        >
                            <Plus size={18} className="mr-2" /> Create New
                        </button>
                    </div>

                    <div className="relative w-full sm:w-64 mb-4">
                        <input
                            type="text"
                            placeholder="Search Redirects..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-[#2a2d3e] text-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200 w-full"
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Search size={18} className="text-gray-500" />
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                <tr>
                                    <th className="px-2 sm:px-4 py-2 text-left">Keyword</th>
                                    <th className="px-2 sm:px-4 py-2 text-left">Webhook</th>
                                    <th className="table-hide px-2 sm:px-4 py-2 text-left">Type</th>
                                    <th className="px-2 sm:px-4 py-2 text-center">Status</th>
                                    <th className="px-2 sm:px-4 py-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRedirects.map((redirect) => (
                                    <tr key={redirect.id} className="border-b border-gray-700">
                                        <td className="px-2 sm:px-4 py-2 font-medium">
                                            <div className="break-words">{redirect.keyword}</div>
                                        </td>
                                        <td className="px-2 sm:px-4 py-2">
                                            <div className="break-all">{redirect.webhook}</div>
                                        </td>
                                        <td className="table-hide px-2 sm:px-4 py-2">{redirect.type}</td>
                                        <td className="px-2 sm:px-4 py-2 text-center">
                                            <span className={`px-2 py-1 rounded text-xs font-medium ${redirect.active ? 'bg-green-800 text-green-300' : 'bg-red-800 text-red-300'}`}>
                                                {redirect.active ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td className="px-2 py-2">
                                            <div className="flex flex-wrap justify-center gap-2">
                                                <button
                                                    onClick={() => toggleActive(redirect)}
                                                    className={`p-1 rounded ${redirect.active ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'}`}
                                                    title={redirect.active ? 'Deactivate' : 'Activate'}
                                                >
                                                    {redirect.active ? <X size={16} /> : <Check size={16} />}
                                                </button>
                                                <button
                                                    onClick={() => openEditDialog(redirect)}
                                                    className="p-1 rounded bg-yellow-600 hover:bg-yellow-700"
                                                    title="Edit"
                                                >
                                                    <Edit2 size={16} />
                                                </button>
                                                <button
                                                    onClick={() => deleteRedirect(redirect.id)}
                                                    className="p-1 rounded bg-red-600 hover:bg-red-700"
                                                    title="Delete"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {showPopup && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-150">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">{editingRedirect ? 'Edit Redirect' : 'Create New Redirect'}</h2>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="keyword" className="block text-sm font-medium mb-1">Keyword</label>
                                <input
                                    id="keyword"
                                    name="keyword"
                                    type="text"
                                    defaultValue={editingRedirect ? editingRedirect.keyword : newRedirect.keyword}
                                    onChange={handleInputChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                    placeholder="Enter keyword"
                                />
                            </div>
                            <div>
                                <label htmlFor="webhook" className="block text-sm font-medium mb-1">Webhook</label>
                                <input
                                    id="webhook"
                                    name="webhook"
                                    type="text"
                                    defaultValue={editingRedirect ? editingRedirect.webhook : newRedirect.webhook}
                                    onChange={handleInputChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                    placeholder="Enter webhook URL"
                                />
                            </div>
                            <div>
                                <label htmlFor="type" className="block text-sm font-medium mb-1">Type</label>
                                <div className="relative">
                                    <select
                                        id="type"
                                        name="type"
                                        defaultValue={editingRedirect ? editingRedirect.type : newRedirect.type}
                                        onChange={handleInputChange}
                                        className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none"
                                    >
                                        {redirectTypes.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                        <ChevronDown size={18} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex justify-center space-x-4">
                            <button
                                onClick={() => {
                                    setShowPopup(false);
                                    setEditingRedirect(null);
                                }}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                            >
                                <XCircle size={18} className="mr-2" />
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    if (editingRedirect) {
                                        saveRedirect(editingRedirect);
                                    } else {
                                        createNewRedirect();
                                    }
                                    setShowPopup(false);
                                    setEditingRedirect(null);
                                }}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                            >
                                <Save size={18} className="mr-2" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                , document.body)}
        </div>
    );
};

export default MonitorRedirect;