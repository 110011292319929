import React from 'react';
import { AlertCircle } from 'lucide-react';

const InputsNotAllowedMessage: React.FC = () => (
    <div className="bg-[#212332] border border-yellow-600/20 p-6 rounded-lg shadow-lg mb-6">
        <div className="flex items-center">
            <div className="flex-shrink-0 mr-4">
                <AlertCircle size={32} className="text-yellow-500" />
            </div>
            <div>
                <h4 className="text-lg font-semibold text-yellow-500 mb-2">Inputs Not Allowed</h4>
                <p className="text-gray-300 text-sm leading-relaxed">
                    This monitor is configured to operate with predefined inputs only. Custom inputs are currently disabled for this specific monitor setup.
                </p>
            </div>
        </div>
    </div>
);

export default InputsNotAllowedMessage;