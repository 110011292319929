import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowRight } from 'lucide-react';
import { API_URL } from '../../constants';


interface Field {
    name: string;
    value: string;
}

interface Update {
    version: string;
    fields: Field[];
}


const UpdatePage: React.FC = () => {
    const [updates, setUpdates] = useState<Update[]>([]);

    const getUpdateData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/updates`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            setUpdates(response.data);
        } catch (error) {
            console.error('Error fetching updates', error);
        }
    };

    useEffect(() => {
        getUpdateData();
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-[#2a2d3e] scrollable">
            <div className="flex-grow p-4 sm:p-8">
                <div className="max-w-4xl mx-auto">
                    <h1 className="text-3xl font-bold text-white mb-8">Updates</h1>
                    <div className="space-y-8 mb-8">
                        {updates.map((update, index) => (
                            <div key={index} className="bg-[#212332] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
                                <div className="bg-[#1a1b26] p-4 border-b border-gray-700">
                                    <h2 className="text-xl font-semibold text-white">Update v{update.version}</h2>
                                </div>
                                <div className="p-4 space-y-4">
                                    {update.fields.map((field, i) => (
                                        <div key={i} className="flex flex-col space-y-1 pb-4 last:pb-0 last:border-b-0 border-b border-gray-700">
                                            <h3 className="text-lg font-medium text-blue-400">{field.name}</h3>
                                            <p className="text-gray-300">{field.value}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="bg-[#1a1b26] p-4 flex items-center justify-between">
                                    <div className="flex items-center space-x-2 text-sm text-gray-400">
                                        <img src="http://127.0.0.1:3000/logo.png" alt="Footer Icon" className="w-5 h-5 rounded-full" />
                                        <span>Solvex Tools | Updates</span>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default UpdatePage;