
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowLeft, Save, Trash2, Edit2, XCircle, Plus, Search } from 'lucide-react';
import ReactDOM from 'react-dom';
import { CustomToastContainer } from '../CustomCoastContainer';
import { toast } from 'react-toastify';
import { API_URL } from '../../../constants';



interface ProxyList {
    id: number;
    name: string;
    proxies: string;
}

interface ProxySettingsProps { }

const ProxySettings: React.FC<ProxySettingsProps> = () => {
    const [proxyLists, setProxyLists] = useState<ProxyList[]>([]);
    const [scrape, setScrape] = useState<boolean>(false);
    const [newProxyList, setNewProxyList] = useState<ProxyList>({ id: -1, name: "", proxies: "" });
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingProxy, setEditingProxy] = useState<ProxyList | null>(null);

    useEffect(() => {
        setScrape(true);
    }, []);

    useEffect(() => {
        if (scrape) {
            fetchProxyData();
        }
    }, [scrape]);

    const fetchProxyData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/proxies`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setProxyLists(response.data);
            }
        } catch (error) {
            console.error('Error fetching proxy data:', error);
        }
    };

    // Save a proxy list
    const saveProxyList = async (proxyList: ProxyList) => {
        const toastId = toast.loading("Saving proxy list...");
        try {
            const response = await axios.put(`${API_URL}/v1/proxies`, {
                proxyListData: proxyList.proxies,
                proxyListID: proxyList.id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Proxies updated successfully');
                toast.update(toastId, { render: "Proxy list saved successfully", type: "success", isLoading: false, autoClose: 3000 });
                fetchProxyData();
            }
        } catch (error) {
            console.error('Failed to update proxies', error);
            toast.update(toastId, { render: "Failed to save proxy list", type: "error", isLoading: false, autoClose: 3000 });
        }
    };

    // Delete a proxy list
    const deleteProxyList = async (proxyListID: number) => {
        const toastId = toast.loading("Deleting proxy list...");
        try {
            const response = await axios.delete(`${API_URL}/v1/proxies`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { proxyListID: proxyListID }
            });

            if (response.status === 200) {
                console.log('Proxy list deleted successfully');
                setProxyLists(prev => prev.filter(p => p.id !== proxyListID));
                toast.update(toastId, { render: "Proxy list deleted successfully", type: "success", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error('Failed to delete proxy list', error);
            toast.update(toastId, { render: "Failed to delete proxy list", type: "error", isLoading: false, autoClose: 3000 });
        }
    };

    // Create a new proxy list
    const createNewProxyList = async () => {
        if (newProxyList.name) {


            const toastId = toast.loading("Creating new proxy list...");
            try {
                const response = await axios.post(`${API_URL}/v1/proxies`, {
                    proxyListData: newProxyList.proxies,
                    proxyListName: newProxyList.name,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                });

                if (response.status === 200) {
                    console.log('ProxyList created successfully');
                    setShowPopup(false);

                    const updatedProxyList = {
                        ...newProxyList,
                        id: response.data.data.new_id
                    };
                    console.log(updatedProxyList);

                    setProxyLists((prevProxies) => [
                        ...prevProxies,
                        updatedProxyList
                    ]);

                    setNewProxyList({ id: -1, name: "", proxies: "" });
                    toast.update(toastId, { render: "New proxy list created successfully", type: "success", isLoading: false, autoClose: 3000 });
                }
            } catch (error) {
                console.error('Failed to create proxyList', error);
                toast.update(toastId, { render: "Failed to create new proxy list", type: "error", isLoading: false, autoClose: 3000 });
            }
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewProxyList((prevState) => ({
            ...prevState,
            name: event.target.value
        }));
    };

    const handleProxiesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewProxyList((prevState) => ({
            ...prevState,
            proxies: event.target.value
        }));
    };

    useEffect(() => {
        const appDiv = document.querySelector('.App');
        if ((showPopup || editingProxy) && appDiv) {
            appDiv.classList.add('blur_bg');
        } else if (appDiv) {
            appDiv.classList.remove('blur_bg');
        }

        // Cleanup function in case component unmounts
        return () => {
            if (appDiv) {
                appDiv.classList.remove('blur_bg');
            }
        };
    }, [showPopup, editingProxy]);

    const openEditDialog = (proxy: ProxyList) => {
        setEditingProxy(proxy);
    };

    const filteredProxyLists = proxyLists.filter(proxy =>
        proxy.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="mb-8 bg-[#2a2d3e] text-gray-100 p-8 sm:p-4 proxy-container">
            <CustomToastContainer />
            <div className="mx-auto">
                <h1 className="text-3xl font-bold mb-8">Proxy Settings</h1>

                <div className="dark-container p-4 sm:p-6 rounded-lg mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Proxy Lists</h3>
                        <button
                            onClick={() => setShowPopup(true)}
                            className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded flex items-center"
                        >
                            <Plus size={18} className="mr-2" /> Create New
                        </button>
                    </div>

                    <div className="relative w-full sm:w-64 mb-4">
                        <input
                            type="text"
                            placeholder="Search Proxy Lists..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-[#2a2d3e] text-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200 w-full"
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Search size={18} className="text-gray-500" />
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                <tr>
                                    <th className="px-2 sm:px-4 py-2 text-left">Name</th>
                                    <th className="px-2 sm:px-4 py-2 text-left">Proxies</th>
                                    <th className="px-2 sm:px-4 py-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProxyLists.map((proxyList) => (
                                    <tr key={proxyList.id} className="border-b border-gray-700">
                                        <td className="px-2 sm:px-4 py-2 font-medium">{proxyList.name}</td>
                                        <td className="px-2 sm:px-4 py-2">
                                            {proxyList.proxies.split('\n').length} proxies
                                        </td>
                                        <td className="px-2 py-2 w-24">
                                            <div className="flex justify-center space-x-2">
                                                <button
                                                    onClick={() => openEditDialog(proxyList)}
                                                    className="p-1 rounded bg-yellow-600 hover:bg-yellow-700"
                                                    title="Edit"
                                                >
                                                    <Edit2 size={16} />
                                                </button>
                                                <button
                                                    onClick={() => deleteProxyList(proxyList.id)}
                                                    className="p-1 rounded bg-red-600 hover:bg-red-700"
                                                    title="Delete"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showPopup && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-150">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">Create New Proxy List</h2>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium mb-1">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    value={newProxyList.name}
                                    onChange={handleNameChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                    placeholder="Enter proxy list name"
                                />
                            </div>
                            <div>
                                <label htmlFor="proxies" className="block text-sm font-medium mb-1">Proxies</label>
                                <textarea
                                    id="proxies"
                                    value={newProxyList.proxies}
                                    onChange={handleProxiesChange}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded h-40"
                                    placeholder="Enter proxies (one per line)"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex justify-center space-x-4">
                            <button
                                onClick={() => setShowPopup(false)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                            >
                                <XCircle size={18} className="mr-2" />
                                Cancel
                            </button>
                            <button
                                onClick={createNewProxyList}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                            >
                                <Save size={18} className="mr-2" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                , document.body)}

            {editingProxy && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-150">
                    <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">Edit Proxy List</h2>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="editName" className="block text-sm font-medium mb-1">Name</label>
                                <input
                                    id="editName"
                                    type="text"
                                    value={editingProxy.name}
                                    onChange={(e) => setEditingProxy({ ...editingProxy, name: e.target.value })}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                                />
                            </div>
                            <div>
                                <label htmlFor="editProxies" className="block text-sm font-medium mb-1">Proxies</label>
                                <textarea
                                    id="editProxies"
                                    value={editingProxy.proxies}
                                    onChange={(e) => setEditingProxy({ ...editingProxy, proxies: e.target.value })}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded h-40"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex justify-center space-x-4">
                            <button
                                onClick={() => setEditingProxy(null)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                            >
                                <XCircle size={18} className="mr-2" />
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    saveProxyList(editingProxy);
                                    setEditingProxy(null);
                                }}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                            >
                                <Save size={18} className="mr-2" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                , document.body)}
        </div>
    );
};

export default ProxySettings;
