import React, { useState, useEffect } from 'react';
import { Calendar, Package, Edit2, Plus, CreditCard, ChevronDown, ChevronUp, Search, AlertTriangle } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { toast } from 'react-toastify';
import { CustomToastContainer } from './CustomCoastContainer';

interface Product {
    name: string;
    type: 'monitor' | 'toolbox';
}

interface CustomerData {
    customer_id: number;
    name: string;
    hex_color: string;
    image_url: string;
    next_renewal: string;
    products: Product[];
}

const EditableField: React.FC<{ value: string; onSave: (value: string) => void; type?: string }> = ({ value, onSave, type = 'text' }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    const handleSave = () => {
        onSave(editedValue);
        setIsEditing(false);
    };

    return (
        <div className="group relative">
            {isEditing ? (
                <input
                    type={type}
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                    onBlur={handleSave}
                    onKeyPress={(e) => e.key === 'Enter' && handleSave()}
                    className="bg-gray-700 text-gray-100 px-2 py-1 rounded w-full"
                    autoFocus
                />
            ) : (
                <>
                    <span>{value}</span>
                    <button
                        onClick={() => setIsEditing(true)}
                        className="absolute inset-y-0 right-0 flex items-center pr-2 text-blue-400 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                        <Edit2 size={16} />
                    </button>
                </>
            )}
        </div>
    );
};

const ProductSelector: React.FC<{ products: Product[]; selectedProducts: Product[]; onProductChange: (products: Product[]) => void }> = ({ products, selectedProducts, onProductChange }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleProduct = (product: Product) => {
        if (selectedProducts.some(p => p.name === product.name)) {
            onProductChange(selectedProducts.filter(p => p.name !== product.name));
        } else {
            onProductChange([...selectedProducts, product]);
        }
    };

    return (
        <div className="relative mt-4 md:mt-0">
            <CustomToastContainer />
            <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="w-full md:w-auto bg-[#2a2d3e] text-white px-4 py-2 rounded-lg transition-colors flex items-center justify-center"
            >
                Manage Products
                {showDropdown ? <ChevronUp className="ml-2" size={18} /> : <ChevronDown className="ml-2" size={18} />}
            </button>
            {showDropdown && (
                <div className="absolute z-10 mt-2 w-full md:w-48 rounded-md shadow-lg bg-[#2a2d3e] ring-1 ring-black ring-opacity-5 max-h-60 overflow-y-auto">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {products.map((product) => (
                            <label key={product.name} className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-700">
                                <input
                                    type="checkbox"
                                    checked={selectedProducts.some(p => p.name === product.name)}
                                    onChange={() => toggleProduct(product)}
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                />
                                <span className="ml-2">{product.name}</span>
                            </label>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};



const CustomerCard: React.FC<{ customer: CustomerData; onUpdate: (customer: CustomerData) => void; allProducts: Product[] }> = ({ customer, onUpdate, allProducts }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [editedDate, setEditedDate] = useState(customer.next_renewal);

    const handleUpdate = (field: keyof CustomerData, value: any) => {
        onUpdate({ ...customer, [field]: value });
    };

    const extendRenewal = () => {
        const currentDate = new Date(customer.next_renewal);
        const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        handleUpdate('next_renewal', newDate.toISOString());
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedDate(e.target.value);
    };

    const handleDateBlur = () => {
        if (isValidDate(editedDate)) {
            handleUpdate('next_renewal', new Date(editedDate).toISOString());
        }
        setIsEditingDate(false);
    };

    const handleDateKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (isValidDate(editedDate)) {
                handleUpdate('next_renewal', new Date(editedDate).toISOString());
            }
            setIsEditingDate(false);
        }
    };

    const isValidDate = (dateString: string) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    return (
        <div className="bg-[#212332] rounded-lg shadow-lg mb-6 overflow-hidden transition-all duration-300 ease-in-out">
            <div className="p-4 md:p-6 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="flex items-center mb-4 md:mb-0">
                        <img src={customer.image_url} alt={customer.name} className="w-16 h-16 rounded-full mr-4" />
                        <div>
                            <EditableField
                                value={customer.name}
                                onSave={(value) => handleUpdate('name', value)}
                            />
                            <p className="text-gray-400 text-sm mt-1">ID: {customer.customer_id}</p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                extendRenewal();
                            }}
                            className="bg-indigo-600 hover:bg-indigo-700 text-white px-3 py-1 rounded-lg transition-colors flex items-center mr-4"
                        >
                            <Plus size={18} className="mr-1" />
                            1 Month
                        </button>
                        {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div className="p-4 md:p-6 border-t border-gray-700">
                    <div className="flex flex-col md:flex-row justify-between mb-6">
                        <div className="mb-4 md:mb-0">
                            <p className="text-gray-400 flex items-center mb-2">
                                <Calendar className="mr-2" size={18} />
                                Next Renewal:
                                {isEditingDate ? (
                                    <input
                                        type="date"
                                        value={formatDateForInput(editedDate)}
                                        onChange={handleDateChange}
                                        onBlur={handleDateBlur}
                                        onKeyPress={handleDateKeyPress}
                                        className="bg-gray-700 text-gray-100 px-2 py-1 rounded ml-2"
                                        autoFocus
                                    />
                                ) : (
                                    <span
                                        className="ml-2 cursor-pointer hover:text-blue-400"
                                        onClick={() => {
                                            setIsEditingDate(true);
                                            setEditedDate(customer.next_renewal);
                                        }}
                                    >
                                        {formatDate(customer.next_renewal)}
                                    </span>
                                )}
                            </p>
                            <div className="flex items-center mb-2">
                                <span className="mr-2">Color:</span>
                                <EditableField
                                    value={customer.hex_color}
                                    onSave={(value) => handleUpdate('hex_color', value)}
                                />
                                <div
                                    className="w-6 h-6 rounded-full ml-2"
                                    style={{ backgroundColor: customer.hex_color }}
                                ></div>
                            </div>
                        </div>
                        <ProductSelector
                            products={allProducts}
                            selectedProducts={customer.products}
                            onProductChange={(products) => handleUpdate('products', products)}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-lg font-semibold mb-4 flex items-center">
                                <Package className="mr-2" size={20} />
                                Purchased Monitors
                            </h3>
                            <ul className="space-y-2">
                                {customer.products.filter(p => p.type === 'monitor').map((product, index) => (
                                    <li key={index} className="text-gray-300 capitalize flex items-center">
                                        <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                        {product.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4 flex items-center">
                                <CreditCard className="mr-2" size={20} />
                                Purchased Toolbox
                            </h3>
                            <ul className="space-y-2">
                                {customer.products.filter(p => p.type === 'toolbox').map((product, index) => (
                                    <li key={index} className="text-gray-300 capitalize flex items-center">
                                        <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                                        {product.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const Customers: React.FC = () => {
    const [customers, setCustomers] = useState<CustomerData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState<Product[]>([]);

    const getCustomers = async () => {
        try {
            const response = await axios.get<CustomerData[]>(`${API_URL}/v1/customers`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || ""
                }
            });
            if (response.status === 200) {
                setCustomers(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getProducts = async () => {
        try {
            const response = await axios.get<Product[]>(`${API_URL}/v1/products`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || ""
                }
            });
            if (response.status === 200) {
                setProducts(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCustomers();
        getProducts();
    }, []);

    const updateCustomer = async (customer: CustomerData) => {
        const toastId = toast.loading("Saving customer...");
        try {
            const response = await axios.put(`${API_URL}/v1/customers`, {
                customer: customer,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Customer updated successfully');
                toast.update(toastId, { render: "Customer updated successfully", type: "success", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error('Failed to update customer', error);
            toast.update(toastId, { render: "Failed to save customer", type: "error", isLoading: false, autoClose: 3000 });
        }
    }

    const handleCustomerUpdate = (updatedCustomer: CustomerData) => {
        setCustomers(prevCustomers =>
            prevCustomers.map(c => c.customer_id === updatedCustomer.customer_id ? updatedCustomer : c)
        );
        console.log(updatedCustomer);
        updateCustomer(updatedCustomer);
    };

    const filteredCustomers = customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const upcomingRenewals = customers
        .filter(customer => {
            const renewalDate = new Date(customer.next_renewal);
            const today = new Date();
            const daysUntilRenewal = Math.ceil((renewalDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
            return daysUntilRenewal <= 30;
        })
        .sort((a, b) => new Date(a.next_renewal).getTime() - new Date(b.next_renewal).getTime());

    return (
        <div className="bg-[#2a2d3e] text-gray-100 min-h-screen flex flex-col scrollable">
            <div className="p-4 md:p-8 flex-shrink-0">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8">Customer Admin Dashboard</h1>

                {upcomingRenewals.length > 0 && (
                    <div className="bg-yellow-900 text-yellow-100 p-4 rounded-lg mb-6">
                        <h2 className="text-xl font-semibold mb-2 flex items-center">
                            <AlertTriangle className="mr-2" size={24} />
                            Upcoming Renewals (Next 30 days)
                        </h2>
                        <ul className="space-y-2">
                            {upcomingRenewals.map(customer => (
                                <li key={customer.customer_id} className="flex items-center justify-between">
                                    <span>{customer.name}</span>
                                    <span>{new Date(customer.next_renewal).toLocaleDateString()}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="mb-6 relative">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search customers..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-[#212332] text-gray-100 border border-gray-700 rounded-lg py-2 px-4 pl-10"
                            style={{ position: 'relative', zIndex: 2 }}
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                    </div>
                </div>
            </div>

            <div className="flex-grow overflow-y-auto px-4 md:px-8 pb-20">
                <div className="max-w-6xl mx-auto">
                    {filteredCustomers.map(customer => (
                        <CustomerCard
                            key={customer.customer_id}
                            customer={customer}
                            onUpdate={handleCustomerUpdate}
                            allProducts={products}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};



export default Customers;