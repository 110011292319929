import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';
import { Edit2, Search, RefreshCw } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import CommandPopUp from '../Other/CommandPopUp';
import Tooltip from '@mui/material/Tooltip';
import { API_URL } from '../../constants';


interface Permission {
    channels?: { id: string; permission: boolean; }[];
    members?: { id: string; permission: boolean; }[];
    roles?: { id: string; permission: boolean; color: number }[];
}

interface Commands {
    id: string;
    name: string;
    permissions: Permission;
}

interface Roles {
    id: string;
    name: string;
    color: number;
}

interface Members {
    id: string;
    name: string;
    avatar: string;
}

interface Channels {
    id: string;
    name: string;
}

interface ServerState {
    icon: string;
    id: string;
    name: string;
}

interface BaseCommandsProps {
    handleBlur: () => void;
    handleLogOut: () => void;
    app_id: string;
}

const OptionWithImage = (props: any) => (
    <components.Option {...props}>
        <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', marginRight: '8px', borderRadius: "50%" }} />
        {props.data.name}
    </components.Option>
);

const SingleValueWithImage = (props: any) => (
    <components.SingleValue {...props}>
        <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: "50%" }} />
        {props.data.name}
    </components.SingleValue>
);

const customStyles_server = {
    input: (provided: any) => ({
        ...provided,
        color: "white",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '170px',
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white',
        '&:active': {
            backgroundColor: '#f2f2f2',
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd',
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),
};

const BaseCommands: React.FC<BaseCommandsProps> = ({ handleBlur, handleLogOut, app_id }) => {
    const [commands, setCommands] = useState<Commands[]>([]);
    const [roles, setRoles] = useState<Roles[]>([]);
    const [members, setMembers] = useState<Members[]>([]);
    const [channels, setChannels] = useState<Channels[]>([]);
    const [currentCommand, setCurrentCommand] = useState<Commands | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [guilds, setGuilds] = useState<ServerState[]>([]);
    const [selectedServer, setSelectedServer] = useState<ServerState | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [serverId, setServerId] = useState("");

    const guildsFetchedRef = useRef(false);

    useEffect(() => {
        const getBotGuilds = async () => {
            if (guildsFetchedRef.current) return;
            guildsFetchedRef.current = true;
            try {
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });
                if (response.status === 200) {
                    const fetchedGuilds = response.data.data.bots[0].guilds;
                    setGuilds(fetchedGuilds);
                    if (fetchedGuilds.length > 0) {
                        setSelectedServer(fetchedGuilds[0]);
                    }
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    if (error.response.status === 429) {
                        toast.error('Too many requests. Please wait a moment and try again.');
                    } else {
                        toast.error('An error occurred while fetching guilds. Please try again later.');
                    }
                }
                handleLogOut();
            } finally {
                setIsLoading(false);
            }
        };

        getBotGuilds();
    }, [handleLogOut]);

    useEffect(() => {
        if (selectedServer) {
            getAllData();
        }
    }, [selectedServer]);

    const getAllData = async () => {
        if (selectedServer) {
            await Promise.all([
                getCommands(),
                getGuildRoles(),
                getGuildMembers(),
                getGuildChannels(),
                getAuth2()
            ]);
        }
    };

    const getCommands = async () => {
        if (!selectedServer) return;
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/commands?guild_id=${selectedServer.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            setCommands(response.data.data.bots[0].commands);
            setServerId(selectedServer.id);
        } catch (error) {
            console.error('Error fetching commands', error);
            toast.error('Failed to fetch commands. Please try again.');
        }
    };

    const getGuildRoles = async () => {
        if (!selectedServer) return;
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setRoles(response.data.data.bots[0].roles);
            }
        } catch (error) {
            console.error('Error fetching guild roles', error);
            toast.error('Failed to fetch guild roles. Please try again.');
        }
    };

    const getGuildMembers = async () => {
        if (!selectedServer) return;
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/members`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setMembers(response.data.data.bots[0].members);
            }
        } catch (error) {
            console.error('Error fetching guild members', error);
            toast.error('Failed to fetch guild members. Please try again.');
        }
    };

    const getGuildChannels = async () => {
        if (!selectedServer) return;
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/channels`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setChannels(response.data.data.bots[0].channels);
            }
        } catch (error) {
            console.error('Error fetching guild channels', error);
            toast.error('Failed to fetch guild channels. Please try again.');
        }
    };

    const getAuth2 = async () => {
        try {
            if (selectedServer) {
                let response = await axios.get(`${API_URL}/v1/user/oauth/check`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 206) {
                    const LINK = "https://discord.com/api/oauth2/authorize?client_id=" + app_id + "&redirect_uri=https%3A%2F%2Fdashboard.solvextools.com%2Fcallback&response_type=code&scope=identify%20guilds%20applications.commands.permissions.update&state=" + app_id; //prod

                    // const LINK = `https://discord.com/api/oauth2/authorize?client_id=${app_id}&redirect_uri=http%3A%2F%2F127.0.0.1%3A3000%2Fcallback&response_type=code&scope=identify%20guilds%20applications.commands.permissions.update&state=${app_id}`;
                    window.location.replace(LINK);
                }
            }
        } catch (error) {
            console.error('Error checking OAuth', error);
            toast.error('Failed to verify authentication. Please try logging in again.');
            handleLogOut();
        }
    };

    const handleServerChange = (option: ServerState | null) => {
        setSelectedServer(option);
    };

    const showEditor = (command: Commands) => {
        setCurrentCommand(command);
        setShowPopup(true);
        handleBlur();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        handleBlur();
    };

    const handleShowSuccess = () => {
        toast.success('Successfully updated command permissions!', {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
        getCommands();
    };

    const hasCustomPermissions = (command: Commands): boolean => {
        return (
            (command.permissions.channels?.length ?? 0) > 0 ||
            (command.permissions.roles?.length ?? 0) > 0 ||
            (command.permissions.members?.length ?? 0) > 0
        );
    };

    const filteredCommands = commands.filter(command =>
        command.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return <div className="bg-[#2a2d3e] text-gray-100 p-6 rounded-lg shadow-lg">Loading...</div>;
    }

    return (
        <div className="bg-[#2a2d3e] text-gray-100 p-6 rounded-lg shadow-lg scrollable">
            <ToastContainer />
            <h2 className="text-2xl font-bold mb-6">Base Commands</h2>

            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
                <Select
                    className="w-full sm:w-64"
                    classNamePrefix="select"
                    value={selectedServer}
                    options={guilds}
                    onChange={handleServerChange}
                    getOptionLabel={(option: ServerState) => option.name}
                    getOptionValue={(option: ServerState) => option.id}
                    placeholder="Select Server"
                    styles={customStyles_server}
                    components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                />

                <div className="relative w-full sm:w-64">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search commands..."
                        className="w-full bg-[#212332] text-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
            </div>

            <div className="space-y-4">
                {filteredCommands.map((command) => (
                    <div key={command.id} className="bg-[#212332] rounded-lg p-4 flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <span className="font-medium">{command.name}</span>
                            {hasCustomPermissions(command) && (
                                <Tooltip title="Permissions updated from default" placement='top'>
                                    <div className='updated'><RefreshCw size={16} className="text-blue-400" /></div>
                                </Tooltip>
                            )}
                        </div>
                        <Tooltip title="Update the command permission" placement='top'>
                            <button
                                onClick={() => showEditor(command)}
                                className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                            >
                                <Edit2 size={18} />
                            </button>
                        </Tooltip>
                    </div>
                ))}
            </div>

            {showPopup && currentCommand && selectedServer && ReactDOM.createPortal(
                <CommandPopUp
                    current_command={currentCommand}
                    handle_close={handleClosePopup}
                    roles={roles}
                    members={members}
                    channels={channels}
                    server_id={serverId}
                    app_id={app_id}
                    handle_show_success={handleShowSuccess}
                />,
                document.body
            )}
        </div>
    );
};

export default BaseCommands;